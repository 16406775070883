<template>
  <div class="loading" :class="{ active: isLoading }">
    <div class="loading-container">
      <span class="loading-item" style="--i: 1"></span>
      <span class="loading-item" style="--i: 2"></span>
      <span class="loading-item" style="--i: 3"></span>
      <span class="loading-item" style="--i: 4"></span>
      <span class="loading-item" style="--i: 5"></span>
      <span class="loading-item" style="--i: 6"></span>
      <span class="loading-item" style="--i: 7"></span>
      <span class="loading-item" style="--i: 8"></span>
      <span class="loading-item" style="--i: 9"></span>
      <span class="loading-item" style="--i: 10"></span>
      <span class="loading-item" style="--i: 11"></span>
      <span class="loading-item" style="--i: 12"></span>
    </div>
  </div>
</template>

<script>
import { ref, inject, onMounted, onBeforeUnmount } from "vue";
export default {
  name: "loading",

  setup() {
    const isLoading = ref(false);
    const mitt = inject("mitt");
    onMounted(() => {
      mitt.on("loading", (status) => {
        isLoading.value = status;
      });
    });

    onBeforeUnmount(() => {
      mitt.off("loading");
    });

    return {
      isLoading,
    };
  },
};
</script>
