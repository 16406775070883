import { createRouter, createWebHistory } from "vue-router";
import { getCurrentUser } from "connection/admin/auth";

const routes = [
  {
    path: "",
    redirect: "/home",
    component: () => import("views/front/Layout"),
    children: [
      {
        path: "home",
        name: "Home",
        meta: {
          title: "首頁-全台新商行",
        },
        component: () => import("views/front/Home"),
      },
      {
        path: "productList/:name",
        name: "ProductList",
        meta: { title: "產品目錄-全台新商行" },
        component: () => import("views/front/ProductList"),
      },
      {
        path: "contact",
        name: "Contact",
        meta: { title: "聯繫方式-全台新商行" },
        component: () => import("views/front/Contact"),
      },
      {
        path: "login",
        name: "Login",
        meta: {
          title: "登入-全台新商行",
        },
        component: () => import("views/front/Login"),
      },
    ],
  },
  {
    path: "/administration",
    redirect: "/administration/product",
    meta: { requiresAuth: true },
    component: () => import("views/back/Layout"),
    children: [
      {
        path: "user",
        name: "User",
        meta: { requiresAuth: true, title: "全台新商行後台管理系統" },
        component: () => import("views/back/User"),
      },
      {
        path: "category",
        name: "Category",
        meta: { requiresAuth: true, title: "全台新商行後台管理系統" },
        component: () => import("views/back/Category"),
      },
      {
        path: "brand_category",
        name: "BrandCategory",
        meta: { requiresAuth: true, title: "全台新商行後台管理系統" },
        component: () => import("views/back/BrandCategory"),
      },
      {
        path: "product",
        name: "Product",
        meta: { requiresAuth: true, title: "全台新商行後台管理系統" },
        component: () => import("views/back/Product"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  const requiresAuth = to.matched.some((x) => x.meta.requiresAuth);
  if (requiresAuth && !(await getCurrentUser())) {
    next("/login");
  } else {
    next();
  }
});

export default router;
