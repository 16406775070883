<template>
  <div class="w-100">
    <Loading />
    <router-view />
  </div>
</template>
<script>
import mitt from "mitt";
import Loading from "components/common/Loading";
import { provide } from "vue";
export default {
  components: {
    Loading,
  },
  setup() {
    const emitter = mitt();
    provide("mitt", emitter);
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/all";
</style>
