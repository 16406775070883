import "../noAuth/firebase";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";

const auth = getAuth();

function signUp(user) {
  let { email, password } = user;
  return createUserWithEmailAndPassword(auth, email, password);
}

function singIn(user) {
  const { email, password } = user;
  return signInWithEmailAndPassword(auth, email, password);
}

function logOut() {
  return signOut(auth);
}

function getCurrentUser() {
  return new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(
      auth,
      (user) => {
        unsubscribe();
        resolve(user);
      },
      reject
    );
  });
}

export { signUp, singIn, logOut, getCurrentUser };
